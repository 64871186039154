<template>
  <div>
    <van-radio-group v-model="radio">
      <van-cell-group :border="false">
        <van-cell
          v-for="(item, index) in childList"
          :key="index"
          :title="item.name"
          clickable
          @click="radio = item.id"
          :border="false"
        >
          <template #right-icon>
            <van-radio :name="item.id" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <div class="btn">
      <van-button style="width:260px" type="info" round @click="changeChild()"
        >提交信息</van-button
      >
    </div>
  </div>
</template>

<script>
import api from '../../common/js/api';
export default {
  data() {
    return {
      radio: '',
      childList: [],
    };
  },

  components: {},

  computed: {},

  mounted() {},
  created() {
    this.radio = +window.sessionStorage.getItem('childId');
    this.getStuList();
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background:#FFFFFF');
  },
  beforeDestroy() {
    document.querySelector('body').removeAttribute('style');
  },

  methods: {
    changeChild() {
      window.sessionStorage.setItem('childId', this.radio);
      this.$router.push('/home');
    },
    getStuList() {
      this.$post(api.getStuList, { origin: 1 }).then((res) => {
        if (res.success) {
          this.childList = res.data;
          console.log(this.childList);
        } else {
          this.$toast(res.message);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  text-align: center;
  margin-top: 50px;
}
/deep/.van-radio-group {
  padding: 40px 10px 0 10px;
}
/deep/.van-cell__title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
</style>
